<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px">
    <el-form-item label="客户" prop="customerId">
      <el-select v-model="dataForm.customerName" placeholder="客户名称" clearable filterable allow-create default-first-option>
        <el-option v-for="(option,index) in customerList || []"
                    :key="index" :label="option.name" :value="option.name"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="设备名称" prop="deviceName">
      <!-- <el-input v-model="dataForm.deviceName" placeholder="设备名称"></el-input> -->
      <el-select v-model="dataForm.deviceName" placeholder="设备名称" clearable filterable allow-create default-first-option>
        <el-option v-for="(option,index) in devicesList || []"
                    :key="index" :label="option.name" :value="option.name"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="SN" prop="deviceCode">
      <input-code v-model="dataForm.deviceCode" is-custom></input-code>
    </el-form-item>
    <el-form-item label="状态" prop="state">
      <el-select v-model="dataForm.state" >
        <el-option label="未激活" :value="0"></el-option>
        <el-option label="已激活" :value="1"></el-option>
        <el-option label="黑名单" :value="-1"></el-option>
      </el-select>
    </el-form-item>
    <!-- <el-form-item label="说明">
      <label>ID：ID机器码；CR：CRC的校验码；XX：万能码；SC：识别码(12位)</label>
    </el-form-item> -->
    <el-form-item label="备注" prop="remark">
      <el-input v-model="dataForm.remark" placeholder="备注"></el-input>
    </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import inputCode from '@/components/input-code16'
  export default {
    components: {
      inputCode,
    },
    data () {
      return {
        visible: false,
        dataForm: {
          id: 0,
          customerId: '',
          customerName: '',
          deviceName: '',
          deviceCode: '',
          remark: '',
          state: 0
        },
        dataRule: {
          deviceCode: [
            { required: true, message: '设备识别码不能为空', trigger: 'blur' }
          ],
        },
        customerList: [],
        devicesList: []
      }
    },
    methods: {
      customerChange(v){
        this.dataForm.customerName = this.customerList.find(a=>a.id==v).name
      },
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            this.$http({
              url: this.$http.adornUrl(`/wxs/wxsstock/info/${this.dataForm.id}`),
              method: 'get',
              params: this.$http.adornParams()
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.customerId = data.wxsStock.customerId
                this.dataForm.customerName = data.wxsStock.customerName
                this.dataForm.deviceName = data.wxsStock.deviceName
                this.dataForm.deviceCode = data.wxsStock.deviceCode
                this.dataForm.remark = data.wxsStock.remark
                this.dataForm.state = data.wxsStock.state
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.$http({
              url: this.$http.adornUrl(`/wxs/wxsstock/${!this.dataForm.id ? 'save' : 'update'}`),
              method: 'post',
              data: this.$http.adornData({
                'id': this.dataForm.id || undefined,
                'customerId': this.dataForm.customerId,
                'customerName': this.dataForm.customerName,
                'deviceName': this.dataForm.deviceName,
                'deviceCode': this.dataForm.deviceCode,
                'remark': this.dataForm.remark,
                'state': this.dataForm.state,
              })
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
              }
            })
          }
        })
      },
      loadCustomerList(){
        this.$http({
          url: this.$http.adornUrl('/wxs/wxscustomer/list'),
          method: 'get',
          params: this.$http.adornParams({
            'page': 0,
            'limit': 99999,
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.customerList = data.page.list
          }
        })
      },
      loadDeviceList(){
        this.$http({
          url: this.$http.adornUrl('/wxs/wxsdevices/list'),
          method: 'get',
          params: this.$http.adornParams({
            'page': 0,
            'limit': 99999,
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.devicesList = data.page.list
          }
        })
      }
    },
    created() {
      this.loadCustomerList()
      this.loadDeviceList()
    }
    
  }
</script>
